




















































































































































































































































































































import {Component, Watch} from 'vue-property-decorator';
import ToolbarNavigationItem from '@/components/home/ToolbarNavigationItem.vue';
import ToolTip from "@/components/custom/ToolTip.vue";
import {Invite} from '@/domain/model/invite';
import {Action, Getter, Mutation} from 'vuex-class';
import {Business} from '@/domain/model/types';
import {businessStore} from '@/store/modules/business/businessStore';
import {applicationStore} from '@/store/modules/application';
import CompanyInfoForm from '@/components/business/CompanyInfoForm.vue';
import ContainerCalc from '@/components/mixins/ContainerCalc';
import {mixins} from 'vue-class-component';
import {profileStore} from '@/store/modules/profile';
import ValidationRules from '@/plugins/validations';
import WelcomeToPigeon from '@/components/custom/WelcomeToPigeon.vue';
import DialogTour from '@/components/DialogTour.vue'
import DialogMobileUser from '@/components/DialogMobileUser.vue'
import {tourStore} from "@/store/modules/tour";
import TagManager from "@/mixins/TagManager";


@Component({
  name: 'get-started',
  components: {WelcomeToPigeon, CompanyInfoForm, ToolbarNavigationItem, ToolTip, DialogTour,DialogMobileUser}
})
export default class GetStarted extends mixins(ContainerCalc, TagManager) {

  STEP_1 = 'welcome';
  STEP_2 = 'company_info';
  STEP_3 = 'invite_team';
  STEP_4 = 'org_structure';
  STEP_5 = 'discover';
  formValidation: boolean = false

  companyInfo: Business = {
    street: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    address: '',
    businessCategory: null,
    email: '',
    phone: '',
    website: '',
    name: '',
    employees: '',
    taxId: ''
  };
  employees: any[] = [
    {
      title: '1-10',
      selected: false,
      class: {
        borderRadius: '3px 0 0 3px',
        borderWidth: '1px 0.5px 1px 1px',
        borderStyle: 'solid'
      }
    },
    {
      title: '10-50',
      selected: false,
      class: {
        borderRadius: '0',
        borderWidth: '1px 0.5px 1px 0.5px',
        borderStyle: 'solid'
      }
    },
    {
      title: '50-250',
      selected: false,
      class: {
        borderRadius: '0',
        borderWidth: '1px 0.5px 1px 0.5px',
        borderStyle: 'solid'
      }
    },
    {
      title: '250+',
      selected: false,
      class: {
        borderRadius: '0 3px 3px 0',
        borderWidth: '1px 1px 1px 0.5px',
        borderStyle: 'solid'
      }
    }
  ];

  roles: any[] = [
    {name: 'Associate', value: 1},
    {name: 'Admin', value: 3}
  ];

  invites: Invite[] = [
    new Invite(0, '', '', 'signUp'),
  ];
  doubleEmail: any = {}
  enterpriseBranch: string = '';
  businessPartner: string = '';

  demoPartner: any = {
    id: '',
    name: 'BMW of Manhattan',
    address: '555 W 57th St, New York, NY 10019'
  };

  enterprise: any[] = [];

  partners: any[] = [];

  toolbar: boolean = false;
  stepper: string = '';

  timeout: number = 5000;

  showMobileUser: boolean = false
  showMobileUserConfirm: boolean = false
  showStartTour: boolean = false

  welcomeSubTitle = "The Business web portal is your destination for Pigeon's full<br>" +
      "functionality. Interact with customers and team members, manage<br>" +
      "users, review reports, edit your business settings and much more."

  @Getter fulfilled;
  @Getter todo;
  @Getter error;
  @Getter busy;
  @Getter step;

  @Mutation('setStep') setStep;
  @Mutation('setError') setError;

  @Action('completeStep') completeStep;
  @Action('saveCompanyInfo') saveCompanyInfo;

  get showError() {
    return !!this.error;
  }

  set showError(value) {
    this.setError(value);
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get business() {
    return applicationStore.business;
  }

  get businessName() {
    return this.business?.name
  }

  get isAdmin() {
    return profileStore.isAdmin
  }

  get isOwner() {
    return profileStore.isOwner
  }

  get categories() {
    return applicationStore.businessCategories;
  }

  get steps() {
    const [welcome, companyInfo, invite, structure, discover] = this.fulfilled;
    return [
      {
        id: 0,
        tag: this.STEP_1,
        progress: 0
      },
      {
        id: 1,
        title: 'Company Info',
        tag: this.STEP_2,
        active: this.step === this.STEP_2,
        fulfilled: !!companyInfo,
        progress: 33
      },
      {
        id: 2,
        title: 'Invite your team',
        tag: this.STEP_3,
        active: this.step === this.STEP_3,
        fulfilled: !!invite,
        progress: 66
      },
      // {
      //   id: 3,
      //   title: 'Organization Structure',
      //   tag: this.STEP_4,
      //   active: this.step === this.STEP_4,
      //   fulfilled: !!structure,
      //   progress: 75
      // },
      {
        // id: 4,
        title: 'Get started',
        tag: this.STEP_5,
        active: this.step === this.STEP_5,
        last: true,
        progress: 100
      }
    ];
  }

  get toolbarSteps() {
    return this.steps.slice(1, this.steps.length);
  }

  get progress() {
    const step = this.steps.find((item) => item.tag === this.step);
    return !!step ? step.progress : 0;
  }

  get showToolbar() {
    return this.toolbar;
  }

  set showToolbar(value) {
    this.toolbar = value;
  }

  get checkTeammates() {
    return !this.invites.some(item => ValidationRules.validEmail(item.email) === true)
  }

  get visitedBefore() {
    return profileStore.visited
  }

  @Watch('invites', {deep: true})
  onChangeEmails(after) {
    this.doubleEmail = {}
    this.doubleEmailCounter(after)
  }

  @Watch('step', {deep: true})
  onChangeStep(after) {
    this.handleResize()
    if (after == 'discover' && !this.visitedBefore) this.pushDataLayout('congratulations_screen_opened')
  }

  private doubleEmailCounter(items) {
    items.forEach(item => {
      const email = item.email
      if (item.email) {
        if (this.doubleEmail.hasOwnProperty(email)) {
          this.doubleEmail[email] = this.doubleEmail[email] + 1
        } else {
          this.doubleEmail[email] = 1
        }
      }
    })
  }

  private validateDoubleEmail(v) {
    return !v || this.doubleEmail[v] <= 1 || 'Double email'
  }

  validateExistEmail(v) {
    return !v || !businessStore.inviteEmailExist.includes(v) || 'The email address is already in use by another account'
  }

  private onRemoveInviteRow(item) {
    this.invites.splice(this.invites.findIndex((invite) => invite.index === item.index), 1);
  }

  private onAddInviteRow() {
    this.pushDataLayout('add_team_member_button_activated')
    this.invites.push(new Invite(this.invites.length, '', '', 'signUp'));
    this.$nextTick(() => {
      this.$refs['email-field-' + (this.invites.length - 1)][0].focus();
    });
  }

  private onConnectEnterprise() {
    this.enterprise.push({id: this.enterpriseBranch, ...this.demoPartner});
    this.enterpriseBranch = '';
  }

  private onDisconnectEnterprise(item: any) {
    this.enterprise.splice(this.enterprise.findIndex((value) => value.id === item.id), 1);
  }

  private onConnectPartner() {
    this.partners.push({id: this.businessPartner, ...this.demoPartner});
    this.businessPartner = '';
  }

  private onDisconnectPartner(item: any) {
    this.partners.splice(this.partners.findIndex((value) => value.id === item.id), 1);
  }

  private onShowTour() {
    tourStore.changeTour(1)
  }

  async stepCompanyInfo() {
    this.pushDataLayout('welcome_continue_button_activated')
    if (await this.completeStep(this.STEP_1)) {
      this.setStep(this.STEP_2);
      this.showToolbar = true;
    }
  }

  async stepInvites(payload: any) {
    const data = {tag: this.STEP_2, payload};
    console.log('stepInvites', data);
    if (await this.saveCompanyInfo(data)) {
      await applicationStore.loadBusiness()
      this.pushDataLayout('invite_your_team_screen_opened')
      this.setStep(this.STEP_3);
    }
  }

  async stepCompanyStructure() {
    this.pushDataLayout( 'send_invitations_button_activated')
    const form: any = this.$refs.formInvites;
    if (form.validate()) {
      const ok = await businessStore.inviteUsers(this.invites);
      if (ok) {
        await this.skipToCompanyStructure()
      } else {
        form.validate()
      }
    }
  }

  async skipToCompanyStructure() {
    this.pushDataLayout('skip_button_activated')
    if (await this.completeStep(this.STEP_3)
        && await this.completeStep(this.STEP_4)) {
      //this.setStep(this.STEP_4);
      this.setStep(this.STEP_5);
    }
  }

  stepTakeATour() {
    //todo: save company structure data
    this.skipToTakeATour()
  }

  async skipToTakeATour() {
    if (await this.completeStep(this.STEP_4)) {
      this.setStep(this.STEP_5);
    }
  }

  onEmployeeClicked(employee) {
    this.employees.forEach((item) => item.selected = item.title === employee.title);
  }

  private handleResize() {
    if (this.step === this.STEP_1
        && window.innerWidth <= 600
        && !this.showMobileUserConfirm) {
      this.showMobileUser = true
    } else {
      this.showMobileUser = false
    }
  }

  private checkLastStep() {
    if (!this.isOwner) {
      this.setStep(this.STEP_5)
    }
  }

  private onTour() {
    console.log('onTour')
    tourStore.setTourCompleted(false)
    this.showStartTour = true
  }

  private onConfirmMobileUser() {
    this.showMobileUserConfirm = true
    this.handleResize()
  }
  
  private onTakeTour() {
    window.open('https://support.getpigeon.com/hc/videos#pigeon-business-how-tos', '_blank')
  }

  created() {
    window.addEventListener("resize", this.handleResize);
    this.checkLastStep()
    this.showToolbar = this.isOwner && !!this.step && this.step !== this.STEP_1 && this.step !== this.STEP_5;
    if (this.business) {
      this.companyInfo.name = this.business.name;
      this.companyInfo.businessCategory = this.business.businessCategory;
      this.companyInfo.employees = this.business.employees;
      this.companyInfo.website = this.business.website;
      this.companyInfo.email = this.business.email;
      this.companyInfo.phone = this.business.phone;
      this.companyInfo.address = this.business.address;
      this.companyInfo.taxId = this.business.taxId;
      this.employees.forEach((item) => item.selected = item.title === this.companyInfo.employees);
    }
  }

  updated() {
    this.calcHeight(this.$refs.container);
    this.checkLastStep()
  }

  mounted() {
    this.handleResize()
    this.calcHeight(this.$refs.container);
  }

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    profileStore.firstVisit()
  }
}
